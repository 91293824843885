'use strict';
import Rt3Api  from './rt3api.es6';
import Utility from './utility.es6';


class Activity {

  static configure(options) {
    return Rt3Api.configure(options);
  }

  static get configuration() {
    return Rt3Api.configuration;
  }

  constructor(options = Rt3Api.configuration) {
    this.rt3api = new Rt3Api(options);
  }
  
  roomsBooked(roomOrCode= null, minutes=120) {
    return new Promise((resolve, reject) => {
      this.rt3api.recentBookings(minutes, resolve, reject);
    });
  } 
}

export default Activity;