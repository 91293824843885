'use strict';
const localJquery  = require('jquery');

export default class CustomError {

  constructor(errorDiv) {
    this.$errorDiv = errorDiv || localJquery('.error-box');
  }

  setError(message) {
    if(this.$errorDiv) {
      this.$errorDiv.html(message);
      this.$errorDiv.addClass('show');
    }

  }

  clearError() {
    if(this.$errorDiv) {
      this.$errorDiv.text('');
      this.$errorDiv.removeClass('show');
    }
  }
}
