'use strict';

export default class Config {

  static configure(options) {
    this.hotelId = options.hotelId || this.hotelId;
    this.portalId = options.portalId || this.portalId;
    this.defaultLocale = options.defaultLocale || this.defaultLocale;
    this.defaultCurrency = options.defaultCurrency || this.defaultCurrency;
    this.defaultNumAdults = options.defaultNumAdults || 1;
    this.defaultNumChildren = options.defaultNumChildren || 0;
    this.rootPath = options.rootPath || this.rootPath;
    this.env = options.env || this.env;
    this.defaultTimezone = options.defaultTimezone || this.defaultTimezone;
    this.ipAddress = options.ipAddress || this.ipAddress;
    this.localeFileRoot = options.localeFileRoot || this.localeFileRoot;
    this.countryLocale = options.countryLocale || this.countryLocale || this.defaultLocale;
    return this.configuration;
  }

  static get configuration() {
    return {
      hotelId: this.hotelId,
      portalId: this.portalId,
      defaultLocale: this.defaultLocale,
      defaultCurrency: this.defaultCurrency,
      defaultNumAdults: this.defaultNumAdults,
      defaultNumChildren: this.defaultNumChildren,
      defaultCurrencySymbol: this.defaultCurrencySymbol,
      defaultTimezone: this.defaultTimezone,
      ipAddress: this.ipAddress,
      localeFileRoot: this.localeFileRoot,
      countryLocale: this.countryLocale
    }
  }

  static set hotelId(id) {
    this._hotelId = id;
  }

  static get hotelId() {
    return this._hotelId;
  }

  static set portalId(id) {
    this._portalId = id;
  }
  static get portalId() {
    return this._portalId;
  }

  static set defaultLocale(id) {
    this._defaultLocale = id;
  }
  static get defaultLocale() {
    return this._defaultLocale || 'en';
  }

  static set defaultCurrency(id) {
    this._defaultCurrency = id;
  }
  static get defaultCurrency() {
    return this._defaultCurrency || 'USD';
  }
  static get defaultCurrencySymbol() {
    return this.currencySymbol(this.defaultCurrency);
  }

  static currencySymbol(currencyName) {
    switch (currencyName.toLowerCase()) {
      case 'eur':
        return '&euro;';
        break;
      case 'gbp':
        return '&pound;';
        break;
      case 'aud':
        return '$';
        break;
      case 'inr':
        return  '&#8377;';
        break;
      case 'idr':
        return 'Rp';
        break;
      case 'aed':
        return 'AED';
        break;
      case 'usd':
        return '$';
        break;
      default:
        return currencyName;
    }
  }


  static set defaultNumAdults(num) {
    this._defaultNumAdults = num;
  }
  static get defaultNumAdults() {
    return this._defaultNumAdults || 1;
  }

  static set defaultNumChildren(num) {
    this._defaultNumChildren = num;
  }
  static get defaultNumChildren() {
    return this._defaultNumChildren || 0;
  }



  static set defaultTimezone(id) {
    this._defaultTimezone = id;
  }
  static get defaultTimezone() {
    return this._defaultTimezone || 'America/New_York';
  }

  static set rootPath(id) {
    this._rootPath = id;
  }
  static get rootPath() {
    return this._rootPath || this._rootFromEnv();
  }
  static _rootFromEnv(env = this.env) {
    switch(env) {
      case 'prod':
      case 'production':
      case 'prd':
        return 'https://rt3api-prd.ttaws.com'
      case 'stage':
        return 'https://rt3api-qa.ttaws.com'
      default:
        return 'https://rt3api-dev.ttaws.com'
    }
  }

  static set env(id) {
    this._env = id;
  }
  static get env() {
    return this._env || 'production';
  }

  static set localeFileRoot(id) {
    this._localeFileRoot = id;
  }

  static get localeFileRoot() {
    return this._localeFileRoot || 'https://plugins.traveltripper.io/locales/';
  }

  static set countryLocale(id) {
    this._countryLocale = id;
  }

  static get countryLocale() {
    return this._countryLocale || 'en'; // we can find different countries locale from https://momentjs.com/
  }

}
