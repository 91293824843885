'use strict';

import Config from './config.es6'
require('datejs');
var moment = require('moment-timezone');

const reztripDateFormat = 'yyyy-MM-dd';

export default class Utility {

  static defaultDates() {
    let today = Utility.reztripDateFormat(Date.today());

    let depart = Utility.reztripDateFormat(Date.today().addDays(1));


    return {
      arrive: today,
      depart: depart
    }
  }

  static tonight( timezone = Config.defaultTimezone ) {
    return  moment().tz(timezone).toDate();
  }

  static reztripDateFormat(paramDate) {
    return Date.parse(paramDate).toString(reztripDateFormat);
  }

  static tonightString() {
    const todayDate = Utility.tonight();
    return Utility.reztripDateFormat(todayDate);
  }

  static isValidDate(paramDate){
    return !!(Date.parse(paramDate)); //moment(paramDate).isValid();
  }

  static addDays(paramDate, paramDay){
    return Date.parse(paramDate).addDays(paramDay);
  }

  static isArray(arg){
    return Object.prototype.toString.call(arg) === '[object Array]';
  }

  static getRoomCode(room){
    return room != null && room.code !=null ? room.code : room;
  }
  static get dateFormat() {
    return {month: 'short', day: 'numeric'  ,year: 'numeric'  };
  }
}
