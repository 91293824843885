'use strict';
import $ from 'jquery';

import Rt3Api  from './rt3api.es6';
import Utility from './utility.es6';

export default class BRG {

  static configure(options) {
    return Rt3Api.configure(options);
  }

  static get configuration() {
    return Rt3Api.configuration;
  }

  constructor(options = Rt3Api.configuration){
    Rt3Api.configure(options);
    this.rt3api = new Rt3Api ();
  }





  getOTARates (startDate = null, endDate = null, params = {}) {
    let searchParams ,
        startDateString,
        endDateString,

        retResponse={};

    return new Promise((resolve, reject) => {
      if(startDate){
        if(Utility.isValidDate(startDate)){
          startDateString = Utility.reztripDateFormat(startDate);
          endDateString = endDate && Utility.isValidDate(endDate) ? Utility.reztripDateFormat(endDate) : Utility.reztripDateFormat(Utility.addDays(startDate , 1));

        }else{
          retResponse = { "error_info": {"error_details" : [
                                                    {"error_type"    : "INVALID_DATA",
                                                    "error_messgae" : "Invalid input parameters"
                                                  }]
                                         }
                        };
          reject(retResponse);
          return;
        }
      }else{
        let defaultDates = Utility.defaultDates();
        startDateString = defaultDates.arrive;
        endDateString = defaultDates.depart;
      }

      searchParams = {
        arrival_date    : startDateString ,
        departure_date  : endDateString,
        ip_address      : BRG.configuration.ipAddress
      };


      searchParams = $.extend(searchParams, params);


      this.rt3api.getRateShopping(searchParams).then((response) =>{
          let errors = response.error_info;
          if(errors && errors.error_details.length > 0){
             reject(errors);
             return;
          }else{
              if(response.show_brg ){
              //  retResponse.otaRates    = response.rates;

                response.rates.forEach((ota)=>{
                  retResponse[ota.provider] = ota.rate;
                });
                retResponse.reztripRate = response.discounted_rate;
                resolve(retResponse);
                return;
              }else{
                retResponse = { "error_info": {"error_details" : [
                                                          {"error_type"    : "NOT_ALLOWED",
                                                          "error_messgae" : "BRG rates are not allowed to display."
                                                        }]
                                               }
                              };
                reject(retResponse);
                return;
              }

          }
      });
    });

  }

}
