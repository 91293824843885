'use strict';
import Rt3Api  from './rt3api.es6';
import LiveRate  from './liverate.es6';
import Utility from './utility.es6';
import CalendarUtility from './calendar-utility.es6';
import Config from './config.es6';
import Query from './query.es6';
import Locales from './locales.es6';
import BRG from './brg.es6';
import CustomError from './error.es6';

const localJquery       = require('jquery');
const momentWithLocale  = require('moment/min/moment-with-locales.min');

let jsrender = null;
if (window.jQuery) {
  jsrender = require('jsrender');
} else {
  jsrender = require('jsrender')();
}

class RatesCalendar {

  static configure(options) {
    return Config.configure(options);
  }

  static get configuration() {
    return Config.configuration;
  }

  constructor(options = Rt3Api.configuration) {
    this.rt3api = new Rt3Api(options);
    this.liveRate = new LiveRate(options);
    this.brg  = new BRG(options);
    //this.staticData = defaultCalStaticData;

    this.adults = options.adults || Config.defaultNumAdults;
    this.children = options.children || Config.defaultNumChildren;

    this.currency = options.currency || Config.defaultCurrency;
    this.currencySymbol = Config.currencySymbol(this.currency);
    this.locale  = options.defaultLocale || Config.defaultLocale;
    this.countryLocale = window.navigator.userLanguage || window.navigator.systemLanguage || window.navigator.language || window.navigator.browserLanguage;

    this.arrivalDate = Utility.defaultDates().arrive;
    this.departureDate= Utility.defaultDates().depart;
    this.dateFormat = options.dateFormat || '';
    this.showBRG = options.showBRG || false;

    // following vlaues should be defined in child class
    this.$calendarDiv = '';
    this.$errorDiv =  '';
    this.$footerRateDiv = '';
    this.$crossOutRate = '';
    this.$nightlyRate = ''
    this.$brgRate = '';
    //

    this.rateCalendar = {};
    this.ratesLoadedDates = {};
  }

  fetchLocaleData () {
    Locales.getStrings(this.localeKey, this.locale).then((result) => {
      this.staticData = result;
      this.onLocaleLoded();
    }, (err) => {
      this.staticData = this.defaultStaticData;
      this.onLocaleLoded();
    })
  }

  onLocaleLoded() {
    console.error("onLocaleLoaded is not implemented.")
  }

  renderCalendarAndTonightRate () {
      this.currentMonth = new Date(); //momentWithLocale();
      this.currentMonthHtml ='';
      this.renderMonth();
  }

  setError(message) {
    this.$errorDiv.html(message);
    this.$errorDiv.addClass('show');
    this.$footerRateDiv.addClass('hide');
  }

  clearError() {
    this.$errorDiv.text('');
    this.$errorDiv.removeClass('show');
    this.$footerRateDiv.removeClass('hide');
  }

  buildMonth(month) {
    let monthTemplate = jsrender.templates(CalendarUtility.monthTemplate)
    month.setDate(1);
    this.currentMonthHtml = monthTemplate.render({
      monthName: month.toLocaleDateString(this.locale, {month: 'long'}).toLocaleUpperCase(),
      firstMonth: CalendarUtility.todayIsCurrentMonth(this.currentMonth),
      year: month.getFullYear(),
      dates: this.getMonthDates(month),
      dayLabels: CalendarUtility.orderedDayLabels(CalendarUtility.firstDayOfWeek(), this.locale)
    })
  }

  renderMonth() {
    this.buildMonth(this.currentMonth);
    this.$calendarDiv.html(this.currentMonthHtml);

    this.$calendarMonth = this.$calendarDiv.find('.calendar-month');

    if (this.showRateCalendar) {
      this.$calendarMonth.addClass('calendar-month--with-rates')
    }
    //activate clicks
    this.$calendarDiv.find(".calendar-month__header__control--prev").click(()=>{
      this.moveToPrevMonth();
    })
    this.$calendarDiv.find(".calendar-month__header__control--next").click(()=>{
      this.moveToNextMonth();
    })
    this.$calendarDiv.find(".calendar-month__grid__date").click((event)=>{
      const dateString = localJquery(event.currentTarget).data('date');
      const notAvailable = localJquery(event.currentTarget).hasClass('calendar-month__grid__date--not-available') || localJquery(event.currentTarget).hasClass('calendar-month__grid__date--past-date');

      this.selectDate(dateString, notAvailable);
    })
    this.loadRateCalendarMonth(this.currentMonth);
  }

  moveToNextMonth() {
    this.changeMonth(1);
  }

  moveToPrevMonth() {
    if (!CalendarUtility.todayIsCurrentMonth(this.currentMonth)) {
      this.changeMonth(-1);
    }
  }

  changeMonth(numMonths) {
    this.currentMonth.addMonths(numMonths);
    this.renderMonth();
  }


  inSelectedRange(date) {
    if (this.arrivalDate && Utility.reztripDateFormat(date)==this.arrivalDate) return true
    return this.arrivalDate && date.getTime() >= Date.parse(this.arrivalDate).getTime() && this.departureDate && date.getTime() <= Date.parse(this.departureDate).getTime();
  }

  isStartDate(date) {
    return (this.arrivalDate && Utility.reztripDateFormat(date)==this.arrivalDate);
  }

  isEndDate(date) {
    return (this.departureDate && Utility.reztripDateFormat(date)==this.departureDate);
  }



  isPastDate(date) {
    let d = new Date()
    d.setHours(0,0,0,0);
    return date < d;
  }

  selectDate(datePickerDateText, notAvailable=false) {
    this.clearError();
    let selectedDate = Date.parse(datePickerDateText);
    let selectedDateText = Utility.reztripDateFormat(selectedDate);
    let arrivalDateAsDate = Date.parse(this.arrivalDate)
    if (!this.arrivalDate || this.departureDate || selectedDate.getTime() < arrivalDateAsDate.getTime()) {
      // hasn't started a selection, or both dates have been selected
      // or a selection was made before the existing start date, so start a new selection
      if (notAvailable && false) {
        this.setError(this.staticData.errors.date_not_available);
      } else {
        this.setArrival(selectedDateText);
        this.setDeparture(null);
        this.getRates();
        this.renderMonth();

      }
    } else if( selectedDate.getTime() != arrivalDateAsDate.getTime() ){
      // First determine if there are any unavailable dates in the group
      while(arrivalDateAsDate < selectedDate) {
        let date = Utility.reztripDateFormat(arrivalDateAsDate);
        let rate = this.rateCalendar[date];
        if (rate=="NA" && this.showRateCalendar && false) {
          this.setError(this.staticData.errors.date_range_not_available);
          this.setArrival(null);
          this.setDeparture(null);
          return;
        }
        arrivalDateAsDate.addDays(1);
      }
      this.setDeparture(selectedDateText);
      this.getRates();
      this.renderMonth();
    }

  }

  getMonthDates(originalDate) {
    let date = Date.parse(originalDate.toString("y-MM-d"));
    let dates = [];
    const firstDayOfThisMonth = date.getDay();
    const firstDayOfWeek = CalendarUtility.firstDayOfWeek();
    let lastDayOfWeek = firstDayOfWeek - 1;
    if (lastDayOfWeek <= 0) lastDayOfWeek += 7
    date.setDate(1);
    const curMonth = date.getMonth();
    const curYear = date.getFullYear();
    const endOfMonth = Date.parse(`${curYear}-${curMonth + 1}-01`).addMonths(1);

    //pad from firstDayOfWeek
    let dayOffset = firstDayOfWeek - firstDayOfThisMonth;
    if (dayOffset > 0) {
      dayOffset = dayOffset - 7
    }
    date.addDays(dayOffset)

    //add all the days of the month
    while(date < endOfMonth) {
      let dow = date.getDay();
      dates.push({
        startOfWeek: dow == firstDayOfWeek,
        endOfWeek: dow == lastDayOfWeek,
        number: date.getDate(),
        fullDate: Utility.reztripDateFormat(date),
        inSelection: this.inSelectedRange(date),
        isStartDate: this.isStartDate(date),
        isEndDate: this.isEndDate(date),
        isPast: this.isPastDate(date),
        isOtherMonth: date.getMonth() != curMonth,
        rate: null,
        labels: this.staticData.labels
      })
      date.addDays(1);
    }

    // pad to the end of the week
    let firstDayOfNextMonth = date.getDay();
    if (firstDayOfNextMonth != firstDayOfWeek) {
      for(let i=firstDayOfNextMonth;i<=lastDayOfWeek;i++) {
        let dow = date.getDay();
        dates.push({
          startOfWeek: dow == firstDayOfWeek,
          endOfWeek: dow == lastDayOfWeek,
          number: date.getDate(),
          fullDate: Utility.reztripDateFormat(date),
          inSelection: this.inSelectedRange(date),
          isStartDate: this.isStartDate(date),
          isEndDate: this.isEndDate(date),
          isPast: this.isPastDate(date),
          isOtherMonth: date.getMonth() != curMonth,
          rate: null,
          labels: this.staticData.labels
        })
        date.addDays(1);
      }
    }

    return dates;
  }

  setArrival(date) {
    this.arrivalDate = date;
    if (this.arrivalInput) {
      this.arrivalInput.val(date);
    }
    if (this.arrivalDisplay) {
      this.arrivalDisplay.text(CalendarUtility.formatDate(date, this.locale, this.dateFormat))
    }

  }

  setDeparture(date) {
    this.departureDate = date;
    if (this.departureInput) {
      this.departureInput.val(date);
    }
    if (this.departureDisplay) {
      this.departureDisplay.text(CalendarUtility.formatDate(date, this.locale, this.dateFormat))
    }
  }

  getRates() {
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })
    if (this.arrivalDate && this.departureDate) {
      this.$footerRateDiv.removeClass('show');
      // if (this.showBRG) {
      //   this.$footerRateDiv.find(".overlay-loader").show();
      // }
      let crossOutRatePromise, brgRatePromise, lowestRateRoom;

      if (this.showBRG) {
        this.$brgRate.hide();
        brgRatePromise = this.brg.getOTARates(this.arrivalDate, this.departureDate, {
          adults: this.adults,
          children: this.children
        }).catch((err) => {
          this.$brgRate.html('');
        });
      }

      crossOutRatePromise = this.liveRate.getCrossOutRate({
        arrival_date: this.arrivalDate,
        departure_date: this.departureDate,
        currency: this.currency,
        adults: this.adults,
        children: this.children,
        rateCode: this.bookingSearch.rateCode,
        offerCode: this.bookingSearch.offerCode
      }).catch((resp) => {
        if(resp.error_details) {
          switch(resp.error_details[0].error_type) {
            case 'ALTERNATIVE_RESULTS_PROVIDED':
              this.setError(this.staticData.errors.dates_not_available);
              break;
            case 'MAX_STAY_VIOLATION':
              this.setError(this.staticData.errors.length_of_stay_exceeded);
              break;
          }

        }
      });


      crossOutRatePromise.then((resp) => {
        //first handle croussout rate
        if(resp) {
          let averageRate = this.avgRateOfSelection();
          lowestRateRoom = resp.lowestRateRoom;
          if (lowestRateRoom && lowestRateRoom.rateFound) {
            let lowestRateText = this.currencySymbol + formatter.format(lowestRateRoom.discountedRate);
            let averageRateText = this.currencySymbol + formatter.format(averageRate);
            //if(!this.showBRG) // if brg is true then show rates when showing brg result
            this.$footerRateDiv.addClass('show');
            this.clearError();
            this.$crossOutRate.html('');
            if (averageRate > lowestRateRoom.discountedRate) {
              this.$crossOutRate.html(averageRateText)
            }
            this.$nightlyRate.html(lowestRateText)
          } else {
            console.log(resp);
            //this.$footerRateDiv.removeClass('show');
            this.setError(this.staticData.errors.date_not_available);
          }
        }

        // handle brg promise result
        if (this.showBRG) {
          return brgRatePromise;
        }


      }).then((resp) => {
        if (this.showBRG) {
          let brgHtml = '';
          const maxEntries = 3;
          let entries = 0;
          for(let provider in resp) {
            const providerRate = resp[provider];
            if (provider != 'reztripRate' && providerRate >= lowestRateRoom.discountedRate) {
              brgHtml += '<span>' + provider+ ': <b>' + this.currencySymbol + formatter.format(providerRate) + '</b></span>';
              entries += 1;
            }
            if (entries >= maxEntries) {
              break;
            }
          }
        //  this.$footerRateDiv.find(".overlay-loader").fadeOut(1500);
          this.$footerRateDiv.addClass('show', 500);
          if (brgHtml) {

            this.clearError();
            this.$brgRate.html(brgHtml).show();
          } else {
            this.$brgRate.html('').hide()
          }
        }
      }).catch((err) => {
        console.log(err);
      })
      // this.liveRate.getCrossOutRate({
      //   arrival_date: this.arrivalDate,
      //   departure_date: this.departureDate,
      //   currency: this.currency,
      //   adults: this.adults,
      //   children: this.children,
      //   rateCode: this.bookingSearch.rateCode,
      //   offerCode: this.bookingSearch.offerCode
      // }).then((resp) => {
      //   let averageRate = this.avgRateOfSelection();
      //   let lowestRateRoom = resp.lowestRateRoom;
      //   if (lowestRateRoom && lowestRateRoom.rateFound) {
      //     let lowestRateText = this.currencySymbol + formatter.format(lowestRateRoom.discountedRate);
      //     let averageRateText = this.currencySymbol + formatter.format(averageRate);
      //     this.$footerRateDiv.addClass('show');
      //     this.clearError();
      //     this.$crossOutRate.html('');
      //     if (averageRate > lowestRateRoom.discountedRate) {
      //       this.$crossOutRate.html(averageRateText)
      //     }
      //     this.$nightlyRate.html(lowestRateText)
      //   } else {
      //     console.log(resp);
      //     this.$footerRateDiv.removeClass('show');
      //   }
      //
      //   // fetch BRG rates
      //   if (this.showBRG) {
      //     this.$brgRate.hide();
      //     this.brg.getOTARates(this.arrivalDate, this.departureDate, {
      //       adults: this.adults,
      //       children: this.children
      //     }).then(function(resp) {
      //       let brgHtml = '';
      //       const maxEntries = 3;
      //       let entries = 0;
      //       for(let provider in resp) {
      //         const providerRate = resp[provider];
      //         if (provider != 'reztripRate' && providerRate > lowestRateRoom.discountedRate) {
      //           brgHtml += '<span>' + provider+ ': <b>' + this.currencySymbol + formatter.format(providerRate) + '</b></span>';
      //           entries += 1;
      //         }
      //         if (entries >= maxEntries) {
      //           break;
      //         }
      //       }
      //       if (brgHtml) {
      //         this.$footerRateDiv.addClass('show');
      //         this.clearError();
      //         this.$brgRate.html(brgHtml).show();
      //       }
      //
      //     }.bind(this), () => {
      //       this.$brgRate.html('');
      //     });
      //   }
      // }, (resp) => {
      //   if(resp.error_details) {
      //     switch(resp.error_details[0].error_type) {
      //       case 'ALTERNATIVE_RESULTS_PROVIDED':
      //         this.setError(this.staticData.errors.dates_not_available);
      //         break;
      //       case 'MAX_STAY_VIOLATION':
      //         this.setError(this.staticData.errors.length_of_stay_exceeded);
      //         break;
      //     }
      //   }
      //
      // });

    }
  }

  avgRateOfSelection() {
    let arrivalDate = Date.parse(this.arrivalDate);
    let departureDate = Date.parse(this.departureDate);
    let sum=0;
    let numNights = 0;
    while (arrivalDate < departureDate) {
      let date = Utility.reztripDateFormat(arrivalDate);
      let rate = this.rateCalendar[date];
      sum += rate
      numNights += 1
      arrivalDate.addDays(1);
    }
    return sum/numNights;
  }

  ratesLoaded(date) {
    return this.ratesLoadedDates[date];
  }

  loadRateCalendarMonth(date) {
    let self = this;
    let startDate = Date.parse(date);
    startDate.addDays(-8); // get some extra days at the beginning
    let endDate = Date.parse(date);
    endDate.addMonths(1);
    endDate.addDays(8); // get some extra days at the end

    let calKey = `${date}-${self.adults}-${self.children}-${self.offerCode}-${self.rateCode}`
    if (!this.ratesLoaded(calKey)) {
      self.rt3api.getRateCalendar({
        start_date: Utility.reztripDateFormat(startDate),
        end_date: Utility.reztripDateFormat(endDate),
        adults: self.adults,
        children: self.children,
        currency: self.currency,
        offerCode: self.offerCode,
        rateCode: self.rateCode
      }).then((resp) => {
        if(resp && resp.rate_calendar_dates) {
          for(let dateRate of resp.rate_calendar_dates) {
            self.rateCalendar[dateRate.date] = this.isPastDate(Date.parse(dateRate.date)) ? "NA" : dateRate.best_available_rate || "NA";
          }
        }
        self.ratesLoadedDates[calKey] = true;

      }, function(err) {
        self.ratesLoadedDates[calKey] = false;
        console.error(err);
      }).then(()=> {
        self.displayRates();
      })
    } else {
      self.displayRates();
    }
  }

  displayRates() {
    let self = this;
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })

    this.$widget.find("[data-date]").each(function() {
      let $day = localJquery(this);
      let dateString = $day.data('date');
      if (dateString && dateString != '') {
        //let date = Utility.reztripDateFormat(momentWithLocale(dateString).toDate());
        let date = Utility.reztripDateFormat(Date.parse(dateString));

        let rate = self.rateCalendar[date];
        if (rate) {
          if (rate == "NA") {
            localJquery(this).addClass('calendar-month__grid__date--not-available');
            localJquery(this).find(".calendar-month__grid__date__rate").html(self.staticData.labels.not_applicable)
          } else {
            rate = formatter.format(rate)
            let rateText =`${self.currencySymbol} ${rate}`

            localJquery(this).find(".calendar-month__grid__date__rate").html(rateText)
          }
        }
      }
    })
  }

  get bookingSearch() {
    return {
      adults: this.adults,
      children: this.children,
      arrivalDate: this.arrivalDate,
      arrivalDateFormatted: CalendarUtility.formatDate(this.arrivalDate, this.locale, this.dateFormat),
      departureDate: this.departureDate,
      departureDateFormatted: CalendarUtility.formatDate(this.departureDate, this.locale, this.dateFormat),
      currency: this.currency,
      locale: this.locale

    }
  }


}

export default RatesCalendar;
