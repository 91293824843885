'use strict';

import $ from 'jquery';
import Config from './config.es6';
import Query from './query.es6'

/** Class for fetching locales based static text used in any widget */
class Locales {


    /**
     * fetch static strings based on locale from widget's Locale json url.
     * Runs
     * @param widgetName {string} - Required. Widget name for which locale strings needs to be fetched
     * @param locale {string} - Optional.
     * @param {success} function - Optional. function to call with response object from successful call. If not present, just returns the repsonse object value.
     * @param {failure} function - Optional. function to call with the error object from a failed call. If not present, logs the error and returns the error object value.
     * @return {jQueryPromise} A promise that either returns the response/error or calls the passed callbacks.
     */

    static getStrings(widgetName, locale = Config.defaultLocale, success = null, failure = null ) {
      locale = locale.toLowerCase();
      if (Locales.loadedStrings[widgetName] && Locales.loadedStrings[widgetName][locale]) {
        return Locales.loadedStrings[widgetName][locale];
      } else {
        console.log("get strings for", widgetName, locale)
        let localeFileUrl = Config.localeFileRoot + widgetName + '/' + locale + '.json';
        Locales.loadedStrings[widgetName] = this.loadedStrings[widgetName] || {};
        const fnName = widgetName.replace(/-/g,'_') + '_' + locale.replace(/-/g,'_');
        Locales.loadedStrings[widgetName][locale] = Query.getJson(localeFileUrl, null, fnName, success, failure);
        return Locales.loadedStrings[widgetName][locale];
      }
    }
}

Locales.loadedStrings = {}

export default Locales;
