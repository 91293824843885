'use strict';
import $ from 'jquery';
import Config from './config.es6'


//TODO: Cache ALL queries for N-time in local storage based on path/params key

/** Class for wrapping jquery request in a promise based on the specified endpoint. */
class Query {
  /**
   * Run a jQuery ajax.get with the configured host for the specified path with given params.
   * Runs
   * @param path {string} - Required. The endpoint for the ajax query
   * @param {Hash} params - Optional. Hash of params to pass to the get call
   * @param {success} function - Optional. function to call with response object from successful call. If not present, just returns the repsonse object value.
   * @param {failure} function - Optional. function to call with the error object from a failed call. If not present, logs the error and returns the error object value.
   * @return {jQueryPromise} A promise that either returns the response/error or calls the passed callbacks.
   */
  static get(path, params, success, failure) {
    console.log("RT3 Query:", Config.rootPath + path, params);
    return $.ajax({
      url: Config.rootPath + path,
      method: 'GET',
      data: params
    }).then((response)=>{
        if (success) {
          success(response);
        } else {
          return(response);
        }
      }, (error)=>{
        if  (failure) {
          failure(error);
        } else {
          if (console) {
            console.error(error);
          }
          return(error);
        }
    });
  }

  /**
   * Run a jQuery ajax.get with the configured host for jsonp call.
   * Runs
   * @param path {string} - Required. The endpoint for the jsonp service
   * @param {Hash} params - Optional. Hash of params to pass to the get call
   * @param {Hash} jsonCallback - jsonpCallback function
   * @param {success} function - Optional. function to call with response object from successful call. If not present, just returns the repsonse object value.
   * @param {failure} function - Optional. function to call with the error object from a failed call. If not present, logs the error and returns the error object value.
   * @return {jQueryPromise} A promise that either returns the response/error or calls the passed callbacks.
   */
  static getJson(path, params, jsonCallback = 'jsonCallback', success, failure) {

    let cb = function(arg) {
      return arg;
    }

    return $.ajax({
      url:  path,
      method: 'GET',
      data: params,
      cache: true,
      dataType: 'jsonp',
      jsonpCallback: jsonCallback,
      contentType: 'application/json'
    }).then((response)=>{
        if (success) {
          success(response);
        } else {
          return(response);
        }
      }, (jqXHR, textStatus, errorThrown)=>{
        if  (failure) {
          failure(jqXHR);
        } else {
          if (console) {
            console.error('Error: ', jqXHR, textStatus, errorThrown);
          }
          return(jqXHR);
        }
    });
  }
}

export default Query;
