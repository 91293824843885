'use strict';
import Rt3Api  from './rt3api.es6';
import LiveRate  from './liverate.es6';
import Utility from './utility.es6';
import Config from './config.es6';
import OTARates from './brg.es6';
import Query from './query.es6';
import Locales from './locales.es6';
import Cookies from './cookies.es6';
import Behaviors from './behaviors.es6';
const localJquery   = require('jquery');

let jsrender = null;
if (window.jQuery) {
  jsrender = require('jsrender');
} else {
  jsrender = require('jsrender')();
}

const defaultStaticData = require('../locales/conversion-plus/en.yml');

class ConversionPlus {

  static configure(options) {
    return Config.configure(options);
  }

  static get configuration() {
    return Config.configuration;
  }

  static get conversionPlusTemplate() {
    return `<div class="ttweb-conversion-plus">
             <div class="ttweb-conversion-plus__best-rate">
              {{if hideTonight}}
                {{if visitedUser}}
                  <div class="ttweb-conversion-plus__best-rate__title">
                    {{:labels.welcome_back}}
                  </div>
                {{/if}}
                <div class="ttweb-conversion-plus__best-rate__label">
                  {{:labels.guaranteed_price}}
                </div>
                <div class="ttweb-conversion-plus__tonight-rates">
                  <div class="ttweb-conversion-plus__tonight-rates__label">
                    {{:labels.tonight_rate}}
                  </div>
                  <div class="ttweb-conversion-plus__tonight-rates__cost">
                    <span class="ttweb-conversion-plus__lowestcost__actual" {{if primaryColor}}style="color:{{:primaryColor}}"{{/if}}></span>
                    <span class="ttweb-conversion-plus__lowestcost__crossout"></span>
                  </div>
                </div>
              {{else}}
              <div class="ttweb-conversion-plus__resume-search">
                <div class="ttweb-conversion-plus__resume-search__title">{{:labels.resume_search}}</div>
                <div class="ttweb-conversion-plus__resume-search__date-row">
                  <span class="ttweb-conversion-plus__resume-search__date-row__date-check-in">{{:arriveText}}</span>
                  <span class="ttweb-conversion-plus__resume-search__date-row__date-to" {{if primaryColor}}style="color:{{:primaryColor}}"{{/if}}>{{:labels.to}}</span>
                  <span class="ttweb-conversion-plus__resume-search__date-row__date-check-out">{{:departText}}</span>
                </div>
              </div>
              {{/if}}
            </div>
            {{if showOta}}
              <div class="ttweb-conversion-plus__best-rate__show">
               <div class="ttweb-conversion-plus__best-rate__label">
                 {{:labels.tonight_rate}}
               </div>
               <div class="ttweb-conversion-plus__best-rate__cost">
                 <span class="ttweb-conversion-plus__lowestcost__actual" {{if primaryColor}}style="color:{{:primaryColor}}"{{/if}}></span>
                 <span class="ttweb-conversion-plus__lowestcost__crossout"></span>
               </div>
              </div>
              <div class="ttweb-conversion-plus__ota-rates">
                <div class="ttweb-conversion-plus__ota-rates__rate">
                {{if hideTonight === false}}
                  <div class="ttweb-conversion-plus__ota-rates__rate__label">
                     {{:labels.guaranteed_price}}
                  </div>
                  <div class="ttweb-conversion-plus__ota-rates__rate__cost">
                     <span class="ttweb-conversion-plus__lowestcost__actual" {{if primaryColor}}style="color:{{:primaryColor}}"{{/if}}></span>
                     <span class="ttweb-conversion-plus__lowestcost__crossout"></span>
                  </div>
                {{/if}}
                </div>
                <div class="ttweb-conversion-plus__divider"></div>
              </div>
            {{/if}}
            <div class="ttweb-conversion-plus__offer"></div>
            <a title="book now" href="{{:booknowUrl}}" class="ttweb-conversion-plus__btn-book-now">{{:labels.book_now}}</a>
            <span class="ttweb-conversion-plus__close">&times;</span>
          </div>`;
  }

  static get otaRowTemplate() {
    return `<div class="ttweb-conversion-plus__ota-rates__rate">
              <div class="ttweb-conversion-plus__ota-rates__rate__label">
                 {{:otaProvider}}
              </div>
              <div class="ttweb-conversion-plus__ota-rates__rate__cost">
                 <span class="ttweb-conversion-plus__ota-rates__cost__price">{{:otaRate}}</span>
              </div>
            </div>`;
  }

  static get specialTemplate() {
    return `{{if show}}
               <span aria-hidden="true" class="elegant-icon elegant-icon--gift-alt" {{if primaryColor}} style="color:{{:primaryColor}}"{{/if}}></span>
               <div class="ttweb-conversion-plus__offer__label">{{:htmlOfferHeading}}</div>
               <div class="ttweb-conversion-plus__offer__title">{{:title}}</div>
               {{if showDetail}}
               <div class="ttweb-conversion-plus__offer__description">{{:description}}</div>
               {{/if}}
            {{/if}}`;
  }
  constructor(options = Rt3Api.configuration) {
    this.isReady = false;
    this.readyCallbacks = [];
    this.rt3api = new Rt3Api(options);
    this.liveRate = new LiveRate(options);
    this.OTARates = new OTARates(options);
    this.adults = options.adults || Config.defaultNumAdults;
    this.children = options.children || Config.defaultNumChildren;

    this.currency = options.currency || Config.defaultCurrency;
    this.currencySymbol = Config.currencySymbol(this.currency);
    this.locale  = options.defaultLocale || Config.defaultLocale;
    this.arrivalDate = Utility.defaultDates().arrive;
    this.departureDate= Utility.defaultDates().depart;
    this.showOtarates = options.showOtarates || false;
    this.oncePerSession = options.oncePerSession || false;
    this.delayTime = options.delayTime || 1000;
    this.showOffer = options.showOffer || false;
    this.showOfferDetail = options.showOfferDetail || false,
    this.offerCode = options.offerCode || null;
    this.offerHeading = options.offerHeading || 'Exclusive Offer';
    this.primaryColor = options.primaryColor || false;

    Locales.getStrings('conversion-plus', this.locale).then((result)=> {
        this.staticData = result;
        this.completeNextSteps();
    }, (err)=> {
        this.staticData = defaultStaticData;//this.defaultStaticData();
        this.completeNextSteps();
    });

  }

  completeNextSteps() {
    //hover color
    if (this.primaryColor) {
      this.backgroundColor = 'rgba(' + parseInt(this.primaryColor.slice(-6,-4),16)
                            + ',' + parseInt(this.primaryColor.slice(-4,-2),16)
                            + ',' + parseInt(this.primaryColor.slice(-2),16)
                            +',.8)';
    }

    this.markSiteVisited(); //create Cookies for booking Search

    if (this.isSearchExpired()) {
      this.arrivalCookiesDate   = Cookies.read('cr_arrivalDate');
      this.departureCookiesDate =  Cookies.read('cr_departureDate');
    }

    this.formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });

    let reztripUrl = 'https://'+Config.portalId+'.reztrip.com/search?';
    let htmlTemplate = jsrender.templates(ConversionPlus.conversionPlusTemplate);
    let hideTonightRate = this.isSearchExpired() ? false : true;

    localJquery("body").append(htmlTemplate.render({
      showOta: this.showOtarates,
      booknowUrl: reztripUrl,
      hideTonight: hideTonightRate,
      visitedUser: Cookies.read('ttw-visited') ? true : false,
      arriveText: this.formatDate(this.arrivalCookiesDate || this.arrivalDate),
      departText: this.formatDate(this.departureCookiesDate || this.departureDate),
      primaryColor: this.primaryColor,
      labels: this.staticData.labels
    }));

    this.getTonightRates();
    this.getSpecialWithCode();
    this.activateConversionPlusButtons();
    this.ready();
  }

  ready() {
    this.isReady = true;
    //execute any pending callbacks
    for (let c of this.readyCallbacks) {
      c();
    }
  }

  onReady(callback) {
    if (this.isReady) {
      callback()
    } else {
      this.readyCallbacks.push(callback)
    }
  }

  getTonightRates() {
    let actualElem       = localJquery('.ttweb-conversion-plus__lowestcost__actual, .ttweb-conversion-plus__lowestcost__actual--blue');
    let crossoutElem     = localJquery('.ttweb-conversion-plus__lowestcost__crossout');
    let newArivalDate = this.arrivalCookiesDate || this.arrivalDate;
    let newDepartureDate = this.departureCookiesDate || this.departureDate;
    let reztripUrl       = 'https://'+Config.portalId+'.reztrip.com/search?';


    if (this.arrivalDate && this.departureDate) {
      this.liveRate.getCrossOutRate({
        arrival_date: newArivalDate,
        departure_date: newDepartureDate,
        currency: this.currency,
        adults: this.adults,
        children: this.children
      }).then((resp) => {
         let lowestRateRoom = resp.lowestRateRoom;

         if (lowestRateRoom && lowestRateRoom.rateFound) {
           actualElem.html( this.currencySymbol + this.formatter.format(lowestRateRoom.discountedRate) );
           crossoutElem.html( this.currencySymbol + this.formatter.format( lowestRateRoom.originalRate) );
           this.getConversionOTA(lowestRateRoom.discountedRate);
         }else {
           localJquery(".ttweb-conversion-plus__ota-rates").css('display','none');
           localJquery(".ttweb-conversion-plus__best-rate__label").show();
           let checkAvailHtml = `<a href="${reztripUrl}" class="ttweb-conversion-plus__check-available-btn">Check Availability</a>`;
           localJquery('.ttweb-conversion-plus__lowestcost__actual').parent('div').html(checkAvailHtml);

           if (Cookies.read('ttw-visited') && this.isSearchExpired()) {
             localJquery(".ttweb-conversion-plus__best-rate__show").css('display','inherit');
           }else{
             localJquery(".ttweb-conversion-plus__best-rate__show").css('display','none');
           }
           if (this.primaryColor) {
             localJquery('.ttweb-conversion-plus__check-available-btn').css('color', this.primaryColor);
           }
         }

      });

    }
  }

  getConversionOTA(tonightRate = null) {

    let htmlOtaTemplate = jsrender.templates(ConversionPlus.otaRowTemplate);
    let ota = this.OTARates.getOTARates((this.arrivalCookiesDate || this.arrivalDate), (this.departureCookiesDate || this.departureDate));

    ota.then(response => {
      let otaFound = false;
      for (const key of Object.keys(response)) {
        if (key !=='reztripRate' && tonightRate <= response[key]) {
          localJquery(".ttweb-conversion-plus__ota-rates").append( htmlOtaTemplate.render( { otaProvider: key, otaRate: this.currencySymbol + this.formatter.format( response[key] ) } ) );
          otaFound = true;
        }
        if (!otaFound) {
          localJquery(".ttweb-conversion-plus__ota-rates").css('display','none');
          localJquery(".ttweb-conversion-plus__best-rate__label").show();
          localJquery(".ttweb-conversion-plus__best-rate__show").css('display','block');
        }
      }
    },reject => {
        localJquery(".ttweb-conversion-plus__ota-rates").css('display','none');
        localJquery(".ttweb-conversion-plus__best-rate__label").show();
        localJquery(".ttweb-conversion-plus__best-rate__show").css('display','block');

    });

  }

  //set search arrival and departure input value in cookies
  markSiteVisited() {

    setTimeout(() => {
      Cookies.write('ttw-visited', true,{'path':'/','maxage': Cookies.YearMaxAge });
    },500);

    localJquery(document).on("click",".ttweb-booking-widget__submit", () => {

      let arrival_date = localJquery('.ttweb-booking-widget').find('input[name="arrival_date"]').val();
      let departure_date = localJquery('.ttweb-booking-widget').find('input[name="departure_date"]').val();

      Cookies.write('ttw-visited', true,{'path':'/','maxage': Cookies.YearMaxAge });
      Cookies.write('cr_arrivalDate', arrival_date,{'path':'/','maxage': Cookies.YearMaxAge });
      Cookies.write('cr_departureDate', departure_date,{ 'path':'/','maxage': Cookies.YearMaxAge });

    });
  }

  // if searh date is not expired will return true else return false
  isSearchExpired() {

    if (Cookies.read('cr_arrivalDate') && Cookies.read('cr_departureDate')) {

      let arriveDate   = new Date(Cookies.read( 'cr_arrivalDate' )).setHours(0,0,0,0);
      let departDate = new Date(Cookies.read( 'cr_departureDate' )).setHours(0,0,0,0);
      let newBookingURL = 'https://'+Config.portalId+'.reztrip.com/search?&arrival_date='+Cookies.read( 'cr_arrivalDate' )+'&departure_date='+Cookies.read( 'cr_departureDate' )+'&adults='+this.adults;

      if ( arriveDate > new Date( this.arrivalDate ).setHours(0,0,0,0) ) {
        setTimeout(() => {
          localJquery(document).find('.ttweb-conversion-plus__btn-book-now').attr('href',newBookingURL);
        },1000);
        return true;
      }else{
        return false;
      }
    }
  }

  getSpecialWithCode() {
    if (this.showOffer && typeof this.offerCode === 'string' && this.offerCode) {
      let specialTemplate = jsrender.templates(ConversionPlus.specialTemplate)

      return this.rt3api.getAllSpecialRates().then((result) => {
        let specials = result.special_rates;
        if (specials.length > 0) {
          for(let offer of specials){

            if (offer.rate_plan_code.toLowerCase() === this.offerCode.toLowerCase()) {
            //  console.log(_self.offerCode);
              this.specialHtml = specialTemplate.render({
                show: true,
                htmlOfferHeading: this.offerHeading,
                title: offer.rate_plan_name,
                description: offer.short_description,
                primaryColor : this.primaryColor,
                showDetail: this.showOfferDetail
              });
            }
          }
        }
      }, err => {
        console.error(err);
        this.specialHtml = specialTemplate.render({show: false, htmlOfferHeading: '', title:'' , description: ''})
      }).then(()=>{
        localJquery('.ttweb-conversion-plus').find('.ttweb-conversion-plus__offer').html(this.specialHtml);
        if (localJquery('.ttweb-conversion-plus__btn-book-now')) {
           setTimeout(() => {
             let urlOffer = localJquery('.ttweb-conversion-plus__btn-book-now').attr('href');
             localJquery('.ttweb-conversion-plus__btn-book-now').attr('href', urlOffer+'&rate_code='+this.offerCode.toUpperCase())
           },1000);
        }

      })
    }

  }

  activateConversionPlusButtons() {

    let widgetDiv = localJquery('.ttweb-conversion-plus');
    localJquery('.ttweb-conversion-plus__close').click(() => {
      widgetDiv.fadeOut('slow');
    });

    if (this.primaryColor) {
      localJquery('.ttweb-conversion-plus__btn-book-now').css('background-color', this.primaryColor);
      localJquery('.ttweb-conversion-plus__btn-book-now').hover((event) => {
      localJquery(event.currentTarget).css('background-color', this.backgroundColor);
     },(event)=> {
        localJquery(event.currentTarget).css('background-color', this.primaryColor);
      });
    }

  }

  formatDate(date) {
    if (!date) return '';
    return (Date.parse(date)).toLocaleDateString(this.locale, Utility.dateFormat)
  }


  showConversionPlus(){
    this.onReady(() => {
      localJquery('.ttweb-conversion-plus').fadeIn('slow');
    })
  }

  showExitIntent(){
    setTimeout(() => {
      Behaviors.onLeaveDocument(() => {
         localJquery('.ttweb-conversion-plus').fadeIn('slow');
      });
    },this.delayTime);
  }

}

export default ConversionPlus;
