'use strict';

import $ from 'jquery';
import Config from './config.es6';
import Query from './query.es6'
import Utility from './utility.es6';

export default class Rt3Api {

  //TODO this means that each time we create a new instance, we reset the full configuration...
  static configure(options) {
    return Config.configure(options);
  }
  static get configuration() {
    return Config.configuration;
  }

  constructor(options={}) {
    this.hotelId = options.hotelId || Config.hotelId;
    this.portalId = options.portalId || Config.portalId;
    this.defaultLocale = options.defaultLocale || Config.defaultLocale;
    this.defaultCurrency = options.defaultCurrency || Config.defaultCurrency;
    this.defaultTimezone = options.timezone || Config.timezone;
    this.roomCache = null;
  }

  get defaultParams() {
    return {
      hotel_id: this.hotelId,
      portal_id: this.portalId,
      locale: this.defaultLocale,
      currency: this.defaultCurrency
    }
  }

  getHotelInfo(params = {},  success=null, failure=null) {
    const path = '/hotels/details.json';
    params = $.extend(this.defaultParams, params)
    const queryParams = {
      hotel_id: params.hotel_id || params.hotelId,
      portal_id: params.portal_id || params.portalId,
      locale: params.locale || params.defaultLocale
    };

    return Query.get(path, queryParams, success, failure)
  }

  getPortalInfo(params = {}, success=null, failure=null) {
      const path   = `/portals/${this.portalId}.json`;
      params = $.extend(this.defaultParams, params)
      const queryParams = {
          locale: params.locale || params.defaultLocale,
          currency: params.defaultCurrency
      };
      return Query.get(path, queryParams, success, failure);
  };


  getAllRooms(params = {rooms: 1}, success=null, failure=null) {
      //var self = this;
      const path = '/hotels/rooms.json';
      const queryParams = $.extend(this.defaultParams, params)
      if (this.roomCache) {
        return $.Deferred().resolve(this.roomCache);
      } else {
        return Query.get(path, queryParams, success,failure).then((resp)=>{
          this.roomCache = resp;
          return this.roomCache;
        })
      }
  };


      //https://rt3api-prd.ttaws.com/hotels/rate_calendar.json?hotel_id=TTDEMO&locale=en&portal_id=1&session_id=30&start_date=2017-10-01&end_date=2017-10-30&adults_0=1&children_0=0&rooms=1
  getRateCalendar(searchParams={}, success=null, failure=null) {
    const path = '/hotels/rate_calendar.json';
    if (!searchParams.start_date || !searchParams.end_date) {
      searchParams.initial_load = true;
    }
    const defaultDates = Utility.defaultDates();

    let newParams = {
      currency: searchParams.currency || this.defaultParams.currency,
      start_date: searchParams.start_date || defaultDates.arrive,
      end_date: searchParams.end_date || defaultDates.depart,
      adults_0 : searchParams.adults || 1,
      children_0 : searchParams.children || 0,
      rooms : searchParams.rooms || 1,
      ip_address : searchParams.ip_address || Config.configuration.ip_address,
      rate_code: searchParams.rateCode || null,
      offer_code: searchParams.offerCode || null
    };
    const params = $.extend(this.defaultParams, newParams)
    return Query.get(path, params, success, failure);

  }

  availableRoomsRateList(searchParams={}, success=null, failure=null) {
      const path = '/hotels/roomRateList.json';
      const params = $.extend(this.defaultParams, searchParams)
      return Query.get(path, params, success, failure);
  };

  getAllAvailableRooms(searchParams={}, success=null, failure=null) {
      const path = '/hotels/rooms.json';
      const defaultDates = Utility.defaultDates();

      let newParams = {
        arrival_date_0 : searchParams.arrival_date || defaultDates.arrive,
        departure_date_0 : searchParams.departure_date || defaultDates.depart,
        adults_0 : searchParams.adults || 1,
        children_0 : searchParams.children || 0,
        rooms : searchParams.rooms || 1,
        rate_code: searchParams.rateCode || null,
        offer_code: searchParams.offerCode || null,
        currency: searchParams.currency || this.defaultParams.currency,
        ip_address : searchParams.ip_address //Utility.getIp() ; //TODO : define function to get ip address of client
      };

      var params = $.extend(this.defaultParams,  newParams )
      return Query.get(path, params, success, failure);
  };

  availableRoomsTonight(params , success=null, failure=null) {
      const path = '/hotels/roomRateList.json';

      var queryParams = $.extend(this.defaultParams, {
          arrival_date: Utility.defaultDates.arrive,
          departure_date: Utility.defaultDates.depart,
          adults: params.adults || 1,
          children: params.children || 0,
          rooms: params.rooms || 1
      });
      return Query.get(path, queryParams, success, failure);
  };

  getAllSpecialRates(success=null, failure=null) {
      const path = '/hotels/special_rates.json';
      return Query.get(path, this.defaultParams, success, failure);
  };

  getRoomInfo(searchParams, success=null, failure=null) {
      var path = '/hotels/roomDetails.json';
      var params = $.extend(this.defaultParams, searchParams);
      return Query.get(path, params, success, failure);
  };



  recentBookings(timeCutOffMinutes, success=null, failure=null) {
      const path = '/ext/recentBookings';
      var params = {
          propertyCode: this.hotelId,
          timeCutOffMinutes: timeCutOffMinutes
      };
      return Query.get(path, params, success, failure);
  };


  getRateShopping(searchParams, success=null, failure=null) {
      const path = '/hotels/rateshopping.json';
      const params = $.extend(this.defaultParams, {
        currency: searchParams.currency || this.defaultParams.currency,
        arrival_date: searchParams.arrival_date,
        departure_date: searchParams.departure_date,
        popular_only: searchParams.popular_only || false,
        num_rates_display: searchParams.num_rates_display ||  5,
        client_ip: searchParams.ip_address,
        lowest_rate: searchParams.lowest_rate || null,
        search_lowest: searchParams.search_lowest || true,
        'adults[]': searchParams.adults || 1,
        'children[]': searchParams.children || 0,
        rooms: searchParams.rooms || 1
      });
      return Query.get(path, params, success, failure);

  };

  get searchUrl() {
    let beUrl = this.makeBookingEngineUrl();
    return beUrl + "/search";
  }

  calendarUrl(urlHash = {}) {
    let beUrl = this.makeBookingEngineUrl() + '/calendar';
    let queryParams = '?' ;

    for(let hashKey in urlHash) {
      queryParams += hashKey + "=" + urlHash[hashKey] + "&";
    }
    queryParams = queryParams.replace(/&\s*$/, ""); //remove last &
    if(queryParams != '?')
      beUrl += queryParams;

    return beUrl;

  }

  makeBookingEngineUrl() {
    return `https://${this.portalId}.reztrip.com`;
  }
}
