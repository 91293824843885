'use strict';
import Rt3Api  from './rt3api.es6';
import Utility from './utility.es6';

export default class LiveRate {

  static configure(options) {
    return Rt3Api.configure(options);
  }

  static get configuration() {
    return Rt3Api.configuration;
  }

  constructor(options = Rt3Api.configuration){
    Rt3Api.configure(options);
    this.rt3api = new Rt3Api ();
  }

  /*
   getCrossOutRate function will return Original rate crossed out, discounted rate, and discount percentage
   input
      searchParams - {ip_address: user ip address,
                      room_code : ''} //  'ROOMCODE' for selected room, if not passed then result will return all rooms rate
   output
      [roomcode]
          rateFound       - true if rate is not null
          originalRate
          discountedRate
          discountPercent
          showCrossedOut  - true if there is discount
          roomInfo - room detail info
    ['baseRoom'] - for base room type
        rateFound       - true if rate is not null
        originalRate
        discountedRate
        discountPercent
        showCrossedOut  - true if there is discount
        roomInfo - room detail info
    ['lowestRateRoom'] - for room having lowest rate
        rateFound       - true if rate is not null
        originalRate
        discountedRate
        discountPercent
        showCrossedOut  - true if there is discount
        roomInfo - room detail info

  */

  getCrossOutRate(searchParams){
      
       let   params        = searchParams || {};
             params.ip_address = params.ip_address || LiveRate.configuration.ipAddress 
       let   retResponse   = {},
             roomsList     = [],
             responseError,
             rateFound,
             result_type = ! params.room_code ? 'all' : 'one_room';



       return new Promise((resolve, reject) =>{

         if(!params.ip_address){
             retResponse = { "error_info": {"error_details" : [
                                                       {"error_type"    : "REQUIRED_FIELD_MISSING",
                                                       "error_messgae" : "Required field was missing from request - ip_address"
                                                     }]
                                            }
                           };
             reject(retResponse);

         }

         this.rt3api.getAllAvailableRooms(params).then((response)=> {
             responseError = response.error_info;
             if(responseError && responseError.error_details.length > 0){
                reject(responseError);
             }else{
               if(result_type == 'one_room'){
                  let room = response.rooms.find(function(entry) {
                    return entry.code.toLowerCase() == params.room_code.toLowerCase();
                  });
                  roomsList = typeof room != 'undefined' ? [room] : [];
               }else{
                 roomsList = response.rooms;
               }

               if(roomsList.length > 0){
                 retResponse = this._roomsRate(roomsList);
                 resolve(retResponse);
               }else{
                 retResponse = { "error_info": {"error_details" : [
                                                           {"error_type"    : "NO_ROOM_FOUND",
                                                           "error_messgae"  : "No room is available."
                                                         }]
                                                }
                               };
                 reject(retResponse);
               }

             }
          });
       });
  };

  _roomsRate(roomsList ){
    let retResponse    = {},
        lowestRateRoom = [],
        lowestRate     = -1;


    for(let i=0; i < roomsList.length ; i++){


        if(roomsList[i].min_average_price[0] != null ){
           let origPrice = roomsList[i].min_average_price[0],
               discPrice = roomsList[i].min_discounted_average_price[0] || origPrice,
               discPer,
               roomCode = roomsList[i].code;
               discPer = ((origPrice - discPrice )* 100) / origPrice;
           
           // this if will be executed once to initialize lowestRate with first non null value
           if(lowestRate == -1 && discPrice != null  && discPrice > 0){
              lowestRate = discPrice;
              lowestRateRoom = { 'rateFound'        : true ,
                                 'originalRate'     : origPrice ,
                                 'discountedRate'   : discPrice ,
                                 'discountPercent'  : Math.round(discPer) ,
                                 'showCrossedOut'   : discPrice > 0 && discPrice < origPrice ? true : false ,

                                 'roomInfo'         : roomsList[i]};
           }

           if(discPrice < lowestRate ){

              lowestRate = discPrice;
              lowestRateRoom = { 'rateFound'        : true ,
                                 'originalRate'     : origPrice ,
                                 'discountedRate'   : discPrice ,
                                 'discountPercent'  : Math.round(discPer) ,
                                 'showCrossedOut'   : discPrice > 0 && discPrice < origPrice ? true : false ,

                                 'roomInfo'         : roomsList[i]};

           }



           retResponse[roomCode] = {'rateFound'       : true ,

                                    'originalRate'    : origPrice ,
                                    'discountedRate'  : discPrice ,
                                    'discountPercent' : Math.round(discPer) ,
                                    'showCrossedOut'  : ( discPrice > 0 && discPrice < origPrice ? true : false) ,
                                    'roomInfo'        :   roomsList[i]
                                    };
           if (roomsList[i].is_base_room_type){
             retResponse['baseRoom'] = retResponse[roomCode];
           }
           retResponse['lowestRateRoom'] =  lowestRateRoom;

        }else {
          if (roomsList[i].is_base_room_type){
            retResponse['baseRoom'] = { 'rateFound' : false,
                                    'roomInfo' : roomsList[i]};
          }else{
            retResponse[roomsList[i].code] = {'rateFound' : false,
                                              'roomInfo'  : roomsList[i]};
          }
        }

    }
    return retResponse;
  }
}
