'use strict';
import Rt3Api  from './rt3api.es6';
import Utility from './utility.es6';


export default class Availability {

  static configure(options) {
    return Rt3Api.configure(options);
  }

  static get configuration() {
    return Rt3Api.configuration;
  }

  constructor(options = Rt3Api.configuration){
    this.rt3api = new Rt3Api(options);
  }

  /*
    getRoomsLeft will give number of rooms left of base room type.
    Input
      minRoom - room threshold to show no of rooms left
    output
      showRoomsLeft - true if this section needs to be shown
      roomsLeft
  */

  roomsLeft(paramDate = null, paramRoom = null){
    let searchParams ,
        startDate,
        endDate,
        roomsList,
        retResponse;

    return new Promise((resolve, reject) => {
      if(paramDate){
        if(Utility.isValidDate(paramDate)){
          startDate = Utility.reztripDateFormat(paramDate);
          endDate = Utility.reztripDateFormat(Utility.addDays(paramDate , 1));

        }else{
          retResponse = { "error_info": {"error_details" : [
                                                    {"error_type"    : "INVALID_DATA",
                                                    "error_messgae" : "Invalid input parameters"
                                                  }]
                                         }
                        };
          reject(retResponse);
          return;
        }
      }else{
        let defaultDates = Utility.defaultDates();
        startDate = defaultDates.arrive;
        endDate = defaultDates.depart;
      }
      searchParams = {
        arrival_date    : startDate ,
        departure_date  : endDate,
        ip_address      : Availability.configuration.ipAddress
      };
      this.rt3api.getAllAvailableRooms(searchParams).then((response) => {

      //  console.log(response)
        let roomsList     = response.rooms,
            responseError = response.error_info,
            retResponse   = {};

        if(responseError && responseError.error_details.length > 0){
          reject(responseError);
          return;
        } else {
          if(!paramRoom){
            let room = roomsList.find(function(entry){
              return entry.is_base_room_type;
            });
            if(typeof roomsList != 'undefined') {
              retResponse =  [{'roomsLeft' : room.available_rooms_number,
                                'roomCode'  : room.code,
                                'roomName'  : room.name,
                                'roomCategory' : room.category}];
            } else {
              retResponse = { "error_info": {
                "error_details" : [
                  {
                    "error_type"    : "NO_ROOM_FOUND",
                    "error_messgae"  : "No room is available."
                  }
                ]
              }};
              reject(retResponse);
              return;
            }
          } else {
              let selectedRoom = roomsList.find(function(entry){
                return entry.code.toLowerCase() == paramRoom.toLowerCase();
              });
              if(selectedRoom != null){
                retResponse[selectedRoom.code] =  {
                  'roomsLeft' : selectedRoom.available_rooms_number,
                  'roomCode'  : selectedRoom.code,
                  'roomName'  : selectedRoom.name,
                  'roomCategory' : selectedRoom.category
                };
              } else {
                retResponse = {
                  "error_info": {
                    "error_details" : [{
                      "error_type"    : "NO_ROOM_FOUND",
                      "error_messgae"  : "No room is available."
                    }]
                  }
                };
                reject(retResponse);
                return;
              }
            }
          }
          resolve(retResponse);

      });

    });
  }

  roomsLeftAll(paramDate = null){
    let searchParams ,
        startDate,
        endDate,
        roomsList,
        retResponse;

    return new Promise((resolve, reject) => {
      if(paramDate){
        if(Utility.isValidDate(paramDate)){
          startDate = Utility.reztripDateFormat(paramDate);
          endDate = Utility.reztripDateFormat(Utility.addDays(paramDate , 1));

        }else{
          retResponse = { "error_info": {"error_details" : [
                                                    {"error_type"    : "INVALID_DATA",
                                                    "error_messgae" : "Invalid input parameters"
                                                  }]
                                         }
                        };
          reject(retResponse);
          return;
        }
      }else{
        let defaultDates = Utility.defaultDates();
        startDate = defaultDates.arrive;
        endDate = defaultDates.depart;
      }
      searchParams = {
        arrival_date    : startDate ,
        departure_date  : endDate,
        ip_address      : Availability.configuration.ipAddress
      };
      this.rt3api.getAllAvailableRooms(searchParams).then((response) => {

      //  console.log(response)
        let roomsList     = response.rooms,
            responseError = response.error_info,
            retResponse   = {};

        if(responseError && responseError.error_details.length > 0){
          reject(responseError);
          return;
        } else {
          for(let i in roomsList) {
            retResponse[roomsList[i].code] = {'roomsLeft' : roomsList[i].available_rooms_number,
                                              'roomCode'  : roomsList[i].code,
                                              'roomName'  : roomsList[i].name,
                                              'roomCategory' : roomsList[i].category};
          }
          resolve(retResponse);
        }
      });

    });
  }
}
