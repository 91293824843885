'use strict';
import Rt3Api  from './rt3api.es6';
import Utility from './utility.es6';
import CalendarUtility from './calendar-utility.es6';
import Config from './config.es6';
import Query from './query.es6';
import RatesCalendar from './rates-calendar.es6';

const momentWithLocale  = require('moment/min/moment-with-locales.min');
const localJquery       = require('jquery');
const defaultStaticData = require('../locales/booking-widget/en.yml');
const localeKey = 'booking-widget';

let jsrender = null;
if (window.jQuery) {
  jsrender = require('jsrender');
} else {
  jsrender = require('jsrender')();
}



class Booking extends RatesCalendar {

  static get specialsTemplate() {
    return `<div class="ttweb-booking-widget__body__specials" >
              <div class="ttweb-booking-widget__body__specials__title">
                {{:title}}
                <span class="elegant-icon ttweb-booking-widget__body__specials__title__icon ttweb-booking-widget__body__specials__title__icon--up">
                  &#xe043;
                </span>
                <span class="elegant-icon ttweb-booking-widget__body__specials__title__icon ttweb-booking-widget__body__specials__title__icon--down">
                  &#xe044;
                </span>
              </div>
              <div class="ttweb-booking-widget__body__specials__rates">
      					<ul class="ttweb-booking-widget__body__specials__rates__list">
                  {{for specials}}
                    <li class="ttweb-booking-widget__body__specials__rates__list__item">
                      <label class="ttweb-booking-widget__body__specials__rates__list__item__radio">
                        <input type="radio" name="rate_code" value="{{:rate_plan_code}}">
                        <span class="ttweb-booking-widget__body__specials__rates__list__item__radio__icon"></span>
                        <span class="ttweb-booking-widget__body__specials__rates__list__item__radio__title">{{:rate_plan_name}}</span>
                      </label>
                    </li>
                  {{/for}}
                </ul>
              </div>
            </div>`;
  }

  static get template() {
    return `<div class="ttweb-booking-widget">
      <form action="{{:reztripSearchUrl}}" method="get" target="_blank">
        <input type="hidden" name="locale" value="{{:locale}}" />
        <div class="ttweb-booking-widget__header" tabindex="0" aria-label="{{:labels.your_booking}}">
          <div class="ttweb-booking-widget__header__close" tabindex="0" aria-label="{{:labels.close_your_booking}}">
            <span class='elegant-icon' >&#x4d;</span>
          </div>
          <div class="ttweb-booking-widget__header__title" >{{:labels.your_booking}}</div>
        </div>
        <div class="ttweb-booking-widget__body" >
          <a href="{{:reztripSearchUrl}}" tabindex="0" aria-label="{{:labels.skip_to_booking_engine}}" class="ttweb-booking-widget__body__skip-to" >{{:labels.skip_to_booking_engine}}</a>
          <div class="ttweb-booking-widget__body__flex-dates">
            <a href="{{:reztripCalendarUrl}}" class="ttweb-booking-widget__body__flex-dates__link">
              <span class='ttweb-booking-widget__body__flex-dates__link__label'>{{:labels.flexible_date}}</span>
              <span class='elegant-icon'>&#xe046;</span>
            </a>
          </div>
          <div class='ttweb-booking-widget__body__date-selection'>
            <div class='ttweb-booking-widget__body__date-selection__selected-dates'>
              <div class='ttweb-booking-widget__body__date-selection__selected-dates__selected-date ttweb-booking-widget__body__date-selection__selected-dates__selected-date--checkin' data-content-through={{:labels.through}}>
                <span class='elegant-icon'>&#xe025;</span>
                <span class='ttweb-booking-widget__body__date-selection__selected-dates__selected-date__label'>
                  Check In
                </span>
                <span class='ttweb-booking-widget__body__date-selection__selected-dates__selected-date__display ttweb-booking-widget__body__date-selection__selected-dates__selected-date--checkin__display '>
                  {{:arrivalDateFormatted}}
                </span>
                <input type="hidden" name="arrival_date" value="{{:arrivalDate}}" />
              </div>
              <div class='ttweb-booking-widget__body__date-selection__selected-dates__selected-date ttweb-booking-widget__body__date-selection__selected-dates__selected-date--checkout'>
                <span class='elegant-icon'>&#xe025;</span>
                <span class='ttweb-booking-widget__body__date-selection__selected-dates__selected-date__label'>
                  Check Out
                </span>
                <span class='ttweb-booking-widget__body__date-selection__selected-dates__selected-date__display ttweb-booking-widget__body__date-selection__selected-dates__selected-date--checkout__display'>
                  {{:departureDateFormatted}}
                </span>
                <input type="hidden" name="departure_date" value="{{:departureDate}}" />
              </div>
            </div>
            <div class='ttweb-booking-widget__body__date-selection__calendar'></div>
          </div>

          <!-- Rooms, Adults, Children -->
          <div class="ttweb-booking-widget__body__people">
            <div  class="ttweb-booking-widget__body__people__selector ttweb-booking-widget__body__people__selector--adults">
              <select name="adults[]" aria-label="{{:labels.adults}}">
                <option value="1" {{if adults==1}}selected{{/if}}>1 {{:labels.adult}}</option>
                <option value="2" {{if adults==2}}selected{{/if}}>2 {{:labels.adults}}</option>
                <option value="3" {{if adults==3}}selected{{/if}}>3 {{:labels.adults}}</option>
                <option value="4" {{if adults==4}}selected{{/if}}>4 {{:labels.adults}}</option>
             </select>
            </div>
            <div class="ttweb-booking-widget__body__people__selector ttweb-booking-widget__body__people__selector--children">
              <select name="children[]" aria-label="{{:labels.children}}">
                <option value="0" {{if children==0}}selected{{/if}}>0 {{:labels.children}}</option>
                <option value="1" {{if children==1}}selected{{/if}}>1 {{:labels.child}}</option>
                <option value="2" {{if children==2}}selected{{/if}}>2 {{:labels.children}}</option>
                <option value="3" {{if children==3}}selected{{/if}}>3 {{:labels.children}}</option>
                <option value="4" {{if children==4}}selected{{/if}}>4 {{:labels.children}}</option>              </select>
              <p class="ttweb-booking-widget__body__people__selector__description">*{{:labels.under_age}}</p>
            </div>
          </div>

          <!-- View Special Rates -->
          {{if showOfferCode}}
            <div class="ttweb-booking-widget__body__offer-code">
                <label for="offer_code">
                  {{:labels.offer_code}}
                </label>
                <input id="offer_code_show" type="text" aria-label="{{:labels.offer_code}}" />
                <input name="offer_code" type="hidden" disabled="disabled" />
            </div>
          {{/if}}
          <div class="ttweb-booking-widget__body__specials-wrap" >
          </div>
        </div>
        <div class="ttweb-booking-widget__footer">

          <div class="ttweb-booking-widget__footer__error">
          </div>

          <!-- Rates -->
          <div class="ttweb-booking-widget__footer__rate">
            <!-- BRG Rate-->
            {{if showBRG}}
            <div class="ttweb-booking-widget__footer__rate__brg">
            </div>
            {{/if}}
            <div class="ttweb-booking-widget__footer__rate__label">
              {{:labels.best_price_per_night}}:
            </div>
            <div class="ttweb-booking-widget__footer__rate__cost">
              <span class="ttweb-booking-widget__footer__rate__cost__crossout"></span>
              <span class="ttweb-booking-widget__footer__rate__cost__actual"></span>
            </div>
          </div>

          <button type="submit" class="ttweb-booking-widget__submit" tabindex="0">{{:labels.book_now}}</button>
          <div class="ttweb-booking-widget__footer__powered-by">
                <span>{{:labels.powered_by}}</span>
                <img src="https://plugins.traveltripper.io/images/tt-logo-grey-highres.png" alt="Travel Tripper"/>
                <a href="#" aria-label="{{:labels.close_your_booking}}" class="ttweb-booking-widget__footer__powered-by__close-widget" >X</a>
          </div>

        </div>

      </form>
    </div>`;
  }

  constructor(options = Rt3Api.configuration) {
    super(options);

    this.showRateCalendar = options.showRateCalendar || false;
    this.showSpecials = options.showSpecials == null ? true : options.showSpecials;
    this.showOfferCode = options.showOfferCode || false;
    this.specialsHtml = '';

    this.fetchLocaleData();

  }

  get localeKey() {
    return localeKey;
  }

  get defaultStaticData() {
    return defaultStaticData;
  }

  onLocaleLoded () {
      this.buildWidget();
      this.getSpecials();
      this.renderCalendarAndTonightRate();
  }

  getSpecials() {
    if (this.showSpecials) {
      let specialsTemplate = jsrender.templates(Booking.specialsTemplate)
      let self = this;
      return this.rt3api.getAllSpecialRates().then(function(result) {
        let specials = result.special_rates;
        if (specials.length > 0) {
          specials = [{'rate_plan_code' :'', 'rate_plan_name' : self.staticData.labels.no_thanks}, ...specials];
          self.specialsHtml = specialsTemplate.render({specials: specials, title: self.staticData.labels.view_special_rates});
        }
      }, function(err) {
        console.error(err);
        self.specialsHtml = specialsTemplate.render({specials: [], title: ''})
      }).then(()=>{
        this.$widget.find('.ttweb-booking-widget__body__specials-wrap').html(this.specialsHtml);
        this.$widget.find(".ttweb-booking-widget__body__specials__title").click(function() {
          this.toggleSpecialRates();
        }.bind(self))
        this.$widget.find('input[name=rate_code]').click((event)=> {
          const rateCode = localJquery(event.currentTarget).val();
          this.updateRateCode(rateCode)
        })

      })
    }

  }

  get bookingSearch() {
    return {
      ...super.bookingSearch,
      offerCode: this.offerCode,
      rateCode: this.rateCode
    }
  }

  get renderingObject() {
    return localJquery.extend(this.bookingSearch, {
      reztripSearchUrl: this.rt3api.searchUrl,
      reztripCalendarUrl: this.rt3api.calendarUrl({'locale' : this.locale}),
      showOfferCode: this.showOfferCode,
      showBRG: this.showBRG,
      labels: this.staticData.labels
    })
  }




  updateOfferCode(offerCode) {
    this.offerCode    = offerCode;
    let offerDisabled = offerCode == '' ? true : false;

    localJquery(this.$offerCodeHidden).prop("disabled", offerDisabled).val(this.offerCode);
    this.loadRateCalendarMonth(this.currentMonth);
    this.getRates();
  }

  updateRateCode(rateCode) {
    this.rateCode = rateCode;
    this.loadRateCalendarMonth(this.currentMonth);
    this.getRates();
  }
  updateAdults(adults) {
    this.adults = adults;
    this.loadRateCalendarMonth(this.currentMonth);
    this.getRates();
  }
  updateChildren(children) {
    this.children = children;
    this.loadRateCalendarMonth(this.currentMonth);
    this.getRates();
  }

  toggleSpecialRates() {
    this.$widget.find(".ttweb-booking-widget__body__specials").toggleClass('show');
  }

  buildWidget() {
    let template = jsrender.templates(Booking.template)
    let self = this;
    let html = template.render(this.renderingObject)
    this.$widget = localJquery(html);
    this.$calendarDiv = this.$widget.find('.ttweb-booking-widget__body__date-selection__calendar');
    this.arrivalInput = this.$widget.find("[name=arrival_date]");
    this.arrivalDisplay = this.$widget.find(".ttweb-booking-widget__body__date-selection__selected-dates__selected-date--checkin__display");
    this.departureInput = this.$widget.find("[name=departure_date]");
    this.departureDisplay = this.$widget.find(".ttweb-booking-widget__body__date-selection__selected-dates__selected-date--checkout__display");
    this.$errorDiv = this.$widget.find(".ttweb-booking-widget__footer__error");
    this.$footerRateDiv = this.$widget.find(".ttweb-booking-widget__footer__rate");

    this.$nightlyRate = this.$widget.find(".ttweb-booking-widget__footer__rate__cost__actual");
    this.$crossOutRate = this.$widget.find(".ttweb-booking-widget__footer__rate__cost__crossout");
    this.$brgRate = this.$widget.find(".ttweb-booking-widget__footer__rate__brg");

    this.$adultSelector = this.$widget.find('.ttweb-booking-widget__body__people__selector--adults select');
    this.$childSelector = this.$widget.find('.ttweb-booking-widget__body__people__selector--children select');

    this.$adultSelector.change((event) => {
      const adults = localJquery(event.currentTarget).val();
      this.updateAdults(adults);
    });
    this.$childSelector.change((event) => {
      const children = localJquery(event.currentTarget).val();
      this.updateChildren(children);
    });

    this.$offerCode       = this.$widget.find('.ttweb-booking-widget__body__offer-code #offer_code_show');
    this.$offerCodeHidden = this.$widget.find('.ttweb-booking-widget__body__offer-code input[name="offer_code"]');
    this.$offerCode.change((event) => {
      const offerCode = localJquery(event.currentTarget).val()
      this.updateOfferCode(offerCode);
    })

    this.$widget.find(".ttweb-booking-widget__header__close, .ttweb-booking-widget__footer__powered-by__close-widget").click(()=> {
      this.hideWidget();
    })
    this.$widget.find(".ttweb-booking-widget__footer__powered-by__close-widget").blur((event)=> {
      setTimeout(() => {
          this.hideWidget();
      }, 100);
    })

    this.$widget.find(".ttweb-booking-widget__header__close").keypress((e)=> {
      let keycode = e.which || e.keyCode;
      if(keycode == 13 ) {
          this.hideWidget();
      }
    })


    localJquery("body").append(this.$widget);
    this.getRates();
  }

  hideWidget() {
    if (this.$widget) {
      this.$widget.removeClass('show');
      // set focus to first anchor tag after nav tag when booking widget is closed, considering booking widget was opened from Button in nav tag
      localJquery('nav').nextAll().find('a').first().focus();
    }
  }

  showWidget() {
    if (this.$widget) {
      this.$widget.addClass('show');
      this.$widget.find('.ttweb-booking-widget__header').focus();
    }
  }


}

export default Booking;
