'use strict';
import Utility from './utility.es6';

const momentWithLocale  = require('moment/min/moment-with-locales.min');

export default class CalendarUtility {

  static get monthTemplate() {
    return `<div class='calendar-month'>
      <div class='calendar-month__header'>
        <div class='calendar-month__header__control calendar-month__header__control--prev {{if firstMonth}}calendar-month__header__control--disabled{{/if}}'>
          <span class='elegant-icon'>&#x23;</span>
        </div>
        <div class='calendar-month__header__name'>
          {{:monthName}}
          {{:year}}
        </div>
        <div class='calendar-month__header__control calendar-month__header__control--next'>
          <span class='elegant-icon'>&#x24;</span>
        </div>
      </div>
      <table class='calendar-month__grid'>
          <thead>
            <tr class="calendar-month__grid__header">
              {{for dayLabels}}
                <td>{{:label}}</td>
              {{/for}}
            </tr>
          </thead>
          {{for dates}}
            {{if startOfWeek}}
              <tr>
            {{/if}}
              <td class="calendar-month__grid__date
                  {{if inSelection}}calendar-month__grid__date--in-selection{{/if}}
                  {{if isStartDate}}calendar-month__grid__date--start-selection{{/if}}
                  {{if isEndDate}}calendar-month__grid__date--end-selection{{/if}}
                  {{if isPast}}calendar-month__grid__date--past-date{{/if}}
                  {{if isOtherMonth}}calendar-month__grid__date--other-month{{/if}}"
                  data-date={{:fullDate}} data-content-arrival={{:labels.arrival}} data-content-departure={{:labels.departure}}>
                <span class="calendar-month__grid__date__number">{{:number}}</span>
                <span class="calendar-month__grid__date__rate">{{:rate}}</span>
              </td>
            {{if endOfWeek}}
              </tr>
            {{/if}}
          {{/for}}
      </table>
    </div>`

  }

  get dateFormat() {
    return {month: 'short', day: 'numeric'  ,year: 'numeric'  };
  }

  static formatDate(date, locale = 'en', dateFormat = this.dateFormat) {
    if (!date) return '';
    return (Date.parse(date)).toLocaleDateString(locale, dateFormat);
  }

  static firstDayOfWeek(countryLocale = 'US') {
    return momentWithLocale().locale(countryLocale).startOf('week').isoWeekday();
  }

  static todayIsCurrentMonth(currentMonth) {
    const today = new Date();
    return today.getMonth() == currentMonth.getMonth() && today.getFullYear() == currentMonth.getFullYear();
  }

  static dayLabels(locale) {
    //return momentWithLocale.locale(locale).weekdaysShort();
    let weekday, weekdayArray =[];
    momentWithLocale.locale(locale)
    for(let day = 0; day < 7; day++) {
      weekday =  momentWithLocale.weekdaysShort(day);
      weekdayArray.push(weekday);
    }
    return weekdayArray;
  }

  static orderedDayLabels(firstDay, locale = 'en') {
    const dayLabels = this.dayLabels(locale);
    let list= [];
    for(let i=0; i<7; i++) {
      let idx = i + firstDay;
      if (idx > 6) {
        idx -= 7;
      }

      list.push({
        label: dayLabels[idx]
      })
    }
    return list;
  }
}
