'use strict';
import Rt3Api from './rt3api.es6';
import Utility from './utility.es6';
import Config from './config.es6';
import Query from './query.es6';

import RatesCalendar from './rates-calendar.es6';

const momentWithLocale = require('moment/min/moment-with-locales.min');
const localJquery = require('jquery');
const defaultStaticData = require('../locales/offer-ratecalendar/en.yml');
const localeKey = 'offer-ratecalendar';

let jsrender = null;
if (window.jQuery) {
  jsrender = require('jsrender');
} else {
  jsrender = require('jsrender')();
}


class OfferRateCalendar extends RatesCalendar {

  static get template() {
    return `<div class="ttweb-offercalendar-widget">
      <form action="{{:reztripSearchUrl}}" method="get" target="_blank">
        <input type="hidden" name="locale" value="{{:locale}}" />
        <input type="hidden" name="arrival_date" value="{{:arrivalDate}}" />
        <input type="hidden" name="departure_date" value="{{:departureDate}}" />
        <input name="rate_code" type="hidden" value="{{:rateCode}}" />
        <div class="ttweb-offercalendar-widget__body" >
          <div class='ttweb-offercalendar-widget__body__calendar'></div>
        </div>
        <div class="ttweb-offercalendar-widget__footer">
          <div class="ttweb-offercalendar-widget__footer__error"></div>

          <!-- Rates -->
          <div class="ttweb-offercalendar-widget__footer__rate">
            <div class="ttweb-offercalendar-widget__footer__rate__label">
              {{:labels.best_price_per_night}}:
            </div>
            <div class="ttweb-offercalendar-widget__footer__rate__cost">
              <span class="ttweb-offercalendar-widget__footer__rate__cost__crossout"></span>
              <span class="ttweb-offercalendar-widget__footer__rate__cost__actual"></span>
            </div>
          </div>

          <button type="submit" class="ttweb-offercalendar-widget__submit" tabindex="0">{{:labels.book_now}}</button>
        </div>

      </form>
    </div>`;
  }

  constructor(options = Rt3Api.configuration) {
    super(options);

    this.widgetParentSelector = options.widgetParentSelector; //mandatory
    this.showRateCalendar = options.showRateCalendar || false;
    this.rateCode = options.rateCode; //mandatory

    this.fetchLocaleData();

  }

  onLocaleLoded() {
    this.buildWidget();
    this.renderCalendarAndTonightRate();
  }

  get localeKey() {
    return localeKey;
  }

  get bookingSearch() {
    return {
      ...super.bookingSearch,
      rateCode: this.rateCode
    }
  }

  get renderingObject() {
    return localJquery.extend(this.bookingSearch, {
      reztripSearchUrl: this.rt3api.searchUrl,
      labels: this.staticData.labels
    })
  }
  
  get defaultStaticData() {
    return defaultStaticData;
  }


  buildWidget() {
    let template = jsrender.templates(OfferRateCalendar.template)
    let self = this;
    let html = template.render(this.renderingObject)
    this.$widget = localJquery(html);
    this.$calendarDiv = this.$widget.find('.ttweb-offercalendar-widget__body__calendar');
    this.arrivalInput = this.$widget.find("[name=arrival_date]");
    this.departureInput = this.$widget.find("[name=departure_date]");
    this.$errorDiv = this.$widget.find(".ttweb-offercalendar-widget__footer__error")
    this.$footerRateDiv = this.$widget.find(".ttweb-offercalendar-widget__footer__rate");

    this.$nightlyRate = this.$widget.find(".ttweb-offercalendar-widget__footer__rate__cost__actual");
    this.$crossOutRate = this.$widget.find(".ttweb-offercalendar-widget__footer__rate__cost__crossout");

    localJquery(this.widgetParentSelector).append(this.$widget);
    this.getRates();
  }

  showWidget() {
    if (this.$widget) {
      this.$widget.addClass('show');
    }
  }

}

export default OfferRateCalendar;
