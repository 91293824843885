'use strict';
import Cookies from './cookies.es6';

export default class Behaviors {

  static onLeaveDocument(callback) {
    this.addEvent(document, "mouseout", (e) => {
      e = e ? e : window.event;
      let vpWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

      if (e.clientX >= (vpWidth - 50))
      return;

      if (e.clientY >= 50)
      return;

      let weLeftThePage = e.relatedTarget || e.toElement;
      if (weLeftThePage) {
         callback();
      }
    });
  };

  static addEvent(obj, event, callback) {
		if (obj.addEventListener)
			obj.addEventListener(event, callback, false);
		else if (obj.attachEvent)
			obj.attachEvent("on" + event, callback);
	};

  static oncePerSession(cookieName, delay = 0, callback) {
    setTimeout(function(){
      if (!Cookies.read(cookieName)) {
       callback();
       Cookies.write(cookieName, true, {'path':'/'});
      }
    }, delay);
  }

  static showWithDelay( delay = 0, callback) {
    setTimeout(function(){
       callback();
    }, delay);
  }

}
