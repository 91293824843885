'use strict';

try {
 require('babel-polyfill');
} catch(err) {}

try {
  require('es6-promise').polyfill();
} catch(err) {}


try {
  if (!global.Intl) {
    require('intl');
    require('intl/locale-data/jsonp/en.js');
  }
} catch(err) { console.error(err) }

try {
 require('cookieconsent');
} catch(err) {}


import Utility from './utility.es6';
import Config from './config.es6';
import Locales from './locales.es6';
import Rt3Api  from './rt3api.es6';
import LiveRate from  './liverate.es6';
import BRG from  './brg.es6';
import Availability from  './availability.es6';
import Activity from  './activity.es6';
import Booking from  './booking.es6';
import Behaviors from './behaviors.es6';
import ConversionPlus from  './conversion-plus.es6';
import OfferRateCalendar from  './offer-ratecalendar.es6';

let TTWeb = window.TTWeb || {};
TTWeb.Rt3Api = Rt3Api;

TTWeb.Config = Config;
TTWeb.Locales = Locales;
TTWeb.Utility  = Utility;
TTWeb.LiveRate = LiveRate;
TTWeb.BRG = BRG;
TTWeb.Availability = Availability;
TTWeb.Activity = Activity;
TTWeb.Booking = Booking;
TTWeb.ConversionPlus = ConversionPlus;
TTWeb.Behaviors = Behaviors;
TTWeb.OfferRateCalendar = OfferRateCalendar;

window.TTWeb = TTWeb;


window.addEventListener("load", function(){
  // Load CSS
  var file = "//plugins.traveltripper.io/v2/css/cookieconsent.min.css";

  var link = document.createElement( "link" );
  link.href = file.substr( 0, file.lastIndexOf( "." ) ) + ".css";
  link.type = "text/css";
  link.rel  = "stylesheet";
  link.media = "screen,print";

  document.getElementsByTagName( "head" )[0].appendChild( link );
  let defaultCookieConsentConfig = {
    location: {
      serviceDefinitions: {
        geoIp: () => {
          return {
            url: '//geoip.nekudo.com/api?callback={callback}',
            isScript: true,
            callback: (done, response) => {
              try {
                let json = JSON.parse(response);  //console.log('geoip- ',json.country.code);
                return json.error
                ? new Error('Error in location service')
                : {
                  code: json.country.code
                };
              } catch (err) {
                return console.log({error: 'Invalid response (' + err + ')'});
              }
            }
          };
        },
        ipStack: () => {
          return {
            url: '//api.ipstack.com/check?access_key=cc59db6367e456430aaf9f9da0bfd70b&callback={callback}',
            isScript: true,
            callback: (done, response) => {
              try {
                let json = JSON.parse(response); //console.log('ipstack- ',json.country_code);
                return json.error
                ? new Error('Error [' + (json.error.code || 'UNKNOWN') + ']: ' + json.error.info)
                : {
                  code: json.country_code
                };
              } catch (err) {
                return console.log({error: 'Invalid response (' + err + ')'});
              }
            }
          };
        }
      },
      services: ['geoIp', 'ipStack' ],
    },
    timeout: 5000,
    law: {
      regionalLaw: false
    },
    content: {
      message: "This site uses cookies to provide you with the best possible experience. You may choose to enable or disable cookies in your browser's policy settings.",
      dismiss: 'X',
    },
    elements: {
      dismiss: '<a title="dismiss cookie message" tabindex="0" style="display:block; cursor: pointer; font-size: 20px; background: none; width: auto; min-width: 0;" class="cc-btn cc-dismiss">{{dismiss}}</a>',
    },
    showLink: false,
    palette: {
      popup: {
        background: "#dddddd",
        text: "#333333"
      },
      button: {
        background: "#dddddd",
        text: "#000000"
      }
    }

  };
  let userDefinedCookieConsentConfig = window.cookieConsentConfig || {};
  window.cookieconsent.initialise({...defaultCookieConsentConfig, ...userDefinedCookieConsentConfig});

});
